<template>
    <div class="crossmint-success-page">
        <div class="crossmint-success-page-icon">
            <ICheckmark width="30" />
        </div>
        <h2 class="crossmint-success-page-title">Your order has been completed</h2>
        <div
            class="crossmint-success-page-text"
        >Please close this window and return to the original page</div>
    </div>
</template>
<script setup>
import { onMounted } from "vue-demi";
import ICheckmark from "@/assets/icons/checkmark.svg?inline"
onMounted(() => {
    window.opener?.location?.reload()
})

</script>
<style lang="scss">
.crossmint-success-page {
    text-align: center;
    padding: 15px;

    &-title {
        font-size: 24px;
        text-align: center;
        color: var(--green);
        padding: 0;
    }
    &-text {
        font-size: 18px;
    }
    &-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid var(--green);
        color: var(--green);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }
}
</style>